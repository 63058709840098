import {
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  sortByKey,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { ChangeType } from "src/models";
import { useCreateChangeRequestMutation, useSystemsQuery } from "src/queries";

const FormSchema = z.object({
  change: z.string().trim().min(1),
  reason: z.string().trim().min(1),
  systemId: z.string(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CreateChangeRequest = () => {
  const { data: systems } = useSystemsQuery();
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateChangeRequestMutation();

  const methods = useForm<FormType>({
    defaultValues: {
      change: "",
      reason: "",
      systemId: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        change: data.change,
        reason: data.reason,
        systemId: parseInt(data.systemId),
      });
      addToast("Request received", "success");
      setLocation("/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Request system change" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <SelectField
                autoComplete="off"
                fullWidth
                label="System"
                name="systemId"
                options={
                  systems
                    ?.filter(
                      (system) => system.changeType === ChangeType.MANUAL,
                    )
                    ?.sort(sortByKey((system) => [system.name], "asc"))
                    .map((system) => ({
                      label: system.name,
                      value: system.id.toString(),
                    })) ?? [{ value: "" }]
                }
                required
              />
              <TextField
                fullWidth
                label="Change"
                minRows={2}
                multiline
                name="change"
                autoComplete="off"
                required
              />
              <TextField
                fullWidth
                helperText="Please explain this request to an auditor"
                label="Reason for request"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Request" />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateChangeRequest;
