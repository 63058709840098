import axios from "axios";

import {
  ChangeRequestStateHistory,
  newChangeRequestStateHistory,
} from "src/models";
import { useQuery } from "src/query";

export const useChangeRequestStateHistoryQuery = (changeRequestId: number) => {
  return useQuery<ChangeRequestStateHistory[]>({
    queryKey: ["changeRequests", changeRequestId, "history"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/change-requests/${changeRequestId}/state-history/`,
        { signal },
      );
      return response.data.history.map((data: unknown) =>
        newChangeRequestStateHistory(data),
      );
    },
  });
};
