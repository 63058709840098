import { z } from "zod";

import { ChangeRequestState } from "src/models/changeRequest";

const changeRequestStateHistorySchema = z.object({
  id: z.number().int().positive(),
  changeRequestId: z.number().int().positive(),
  reason: z.string(),
  staffEmail: z.string(),
  staffId: z.number().int().positive(),
  state: z.nativeEnum(ChangeRequestState),
  timestamp: z.coerce.date(),
});

export type ChangeRequestStateHistory = z.infer<
  typeof changeRequestStateHistorySchema
>;

export const newChangeRequestStateHistory = (
  data: unknown,
): ChangeRequestStateHistory => changeRequestStateHistorySchema.parse(data);
