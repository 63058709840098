import { z } from "zod";

export enum ChangeRequestState {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  TESTED = "TESTED",
  COMPLETED = "COMPLETED",
  DENIED = "DENIED",
  WITHDRAWN = "WITHDRAWN",
}

const changeRequestSchema = z.object({
  id: z.number().int().positive(),
  ownerEmail: z.string(),
  ownerId: z.number().int().positive(),
  change: z.string(),
  state: z.nativeEnum(ChangeRequestState),
  systemId: z.number().int().positive(),
  systemName: z.string(),
  timestamp: z.coerce.date(),
});

export type ChangeRequest = z.infer<typeof changeRequestSchema>;

export const newChangeRequest = (data: unknown): ChangeRequest =>
  changeRequestSchema.parse(data);
