import {
  CheckboxField,
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { AccessType, ChangeType, IntegrationType, UARType } from "src/models";

const FormSchema = z
  .object({
    accessType: z.nativeEnum(AccessType),
    changeType: z.nativeEnum(ChangeType),
    description: z.string().min(1),
    integration: z.nativeEnum(IntegrationType),
    integrationArgs: z.string(),
    multirole: z.boolean(),
    name: z.string().min(1),
    requestableRoles: z.string(),
    uarType: z.nativeEnum(UARType),
  })
  .refine(
    (values) =>
      (values.uarType !== UARType.INTEGRATED &&
        values.integration === IntegrationType.NONE) ||
      (values.uarType === UARType.INTEGRATED &&
        values.integration !== IntegrationType.NONE),
    {
      message: "Invalid combination of UAR and Integration types",
      path: ["uarType"],
    },
  );
type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  back: string;
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const SystemForm = ({
  back,
  defaultValues,
  disabled,
  label,
  onSubmit,
}: IProps) => {
  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [methods, defaultValues]);

  const values = methods.watch();

  let placeholder;
  switch (values.integration) {
    case IntegrationType.AWS:
      placeholder =
        '{"aws_access_key_id": "", "aws_secret_access_key": "", "root_account": ""}';
      break;
    case IntegrationType.BITWARDEN:
      placeholder = '{"client_id": "", "client_secret": ""}';
      break;
    case IntegrationType.CEZANNE:
      placeholder = '{"client_id": "", "client_secret": ""}';
      break;
    case IntegrationType.GITHUB:
      placeholder = '{"organisation": "", "token": ""}';
      break;
    case IntegrationType.XERO:
      placeholder = '{"client_id": "", "client_secret": ""}';
      break;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            autoComplete="off"
            required
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            autoComplete="off"
            required
          />
          <SelectField
            fullWidth
            label="Access Type"
            name="accessType"
            options={Object.values(AccessType)
              .sort()
              .map((uarType) => ({
                value: uarType.toString(),
              }))}
            required
          />
          <SelectField
            fullWidth
            label="Change Type"
            name="changeType"
            options={Object.values(ChangeType)
              .sort()
              .map((uarType) => ({
                value: uarType.toString(),
              }))}
            required
          />
          <SelectField
            fullWidth
            label="UAR Type"
            name="uarType"
            options={Object.values(UARType)
              .sort()
              .map((uarType) => ({
                value: uarType.toString(),
              }))}
            required
          />
          <SelectField
            fullWidth
            label="Integration Type"
            name="integration"
            options={Object.values(IntegrationType)
              .sort()
              .map((integrationType) => ({
                value: integrationType.toString(),
              }))}
            required
          />
          <TextField
            fullWidth
            helperText="As a JSON dictionary"
            label="Integration arguments"
            name="integrationArgs"
            placeholder={placeholder}
            autoComplete="off"
          />
          <TextField
            fullWidth
            helperText="Comma seperated roles that users can request access too"
            label="Requestable Roles"
            name="requestableRoles"
            autoComplete="off"
          />
          <CheckboxField
            fullWidth
            label="Multiple roles allowed?"
            name="multirole"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label={label} />
          <FormLinks links={[{ label: "Back", to: back }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default SystemForm;
