import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  ChangeRequest,
  ChangeRequestState,
  newChangeRequest,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useChangeRequestQuery = (requestId: number) =>
  useQuery<ChangeRequest>({
    queryKey: ["changeRequests", requestId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/change-requests/${requestId}/`, {
        signal,
      });
      return newChangeRequest(response.data);
    },
  });

export const useChangeRequestsQuery = () =>
  useQuery<ChangeRequest[]>({
    queryKey: ["changeRequests"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/change-requests/", {
        signal,
      });
      return response.data.changeRequests.map((data: unknown) =>
        newChangeRequest(data),
      );
    },
  });

export const useSystemChangeRequestsQuery = (systemId: number) =>
  useQuery<ChangeRequest[]>({
    queryKey: ["changeRequests", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/change-requests/system/${systemId}/`,
        { signal },
      );
      return response.data.changeRequests.map((data: unknown) =>
        newChangeRequest(data),
      );
    },
  });

interface IChangeRequest {
  change: string;
  reason: string;
  systemId: number;
}

export const useCreateChangeRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IChangeRequest) =>
      await axios.post("/v1/change-requests/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["changeRequests"] });
    },
  });
};

interface IChangeRequestState {
  reason: string;
  state: ChangeRequestState;
}

export const useEditChangeRequestMutation = (requestId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IChangeRequestState) =>
      await axios.post(`/v1/change-requests/${requestId}/state/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["changeRequests"] });
    },
  });
};
