import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";

import {
  AccessType,
  ChangeType,
  IntegrationType,
  System,
  TestUAL,
  UARType,
  newSystem,
  newTestUAL,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useSystemsQuery = () =>
  useQuery<System[]>({
    queryKey: ["systems"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/systems/", { signal });
      return response.data.systems.map((data: unknown) => newSystem(data));
    },
  });

export const useSystemQuery = (
  id: number | undefined,
  options?: Omit<
    UseQueryOptions<any, any, System, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery<System>({
    ...options,
    queryKey: ["systems", id?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/systems/${id}/`, { signal });
      return newSystem(response.data);
    },
    enabled: id !== undefined,
    initialData: () =>
      id !== undefined
        ? queryClient
            .getQueryData<System[]>(["systems"])
            ?.filter((system) => system.id === id)?.[0]
        : undefined,
  });
};

export const useSystemTestUALQuery = (id: number | undefined) =>
  useQuery<TestUAL>({
    queryKey: ["systems", id?.toString(), "test-ual"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/systems/${id}/test-ual/`, {
        signal,
      });
      return newTestUAL(response.data);
    },
    enabled: id !== undefined,
  });

interface ISystemData {
  accessType: AccessType;
  changeType: ChangeType;
  description: string;
  integration: IntegrationType | null;
  integrationArgs?: object;
  multirole: boolean;
  name: string;
  requestableRoles: string[];
  uarType: UARType;
}

export const useCreateSystemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISystemData) =>
      await axios.post("/v1/systems/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systems"] });
    },
  });
};

export const useEditSystemMutation = (systemId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISystemData) =>
      await axios.put(`/v1/systems/${systemId}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systems"] });
    },
  });
};
