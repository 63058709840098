import {
  formatDateTime,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { ChangeRequest, ChangeRequestState } from "src/models";
import { useChangeRequestsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "change" | "systemName" | "timestamp";
type State = "open" | "approved" | "tested" | "complete";

interface IProps {
  state: State;
}

const RequestsTable = ({ state }: IProps) => {
  const { data: requests } = useChangeRequestsQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("systemName");

  const sortKey = (request: ChangeRequest) => [request[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={3} />;

  if (requests !== undefined && requests.length > 0) {
    rows = requests
      .filter(
        (request) =>
          (state === "open" &&
            request.state === ChangeRequestState.REQUESTED) ||
          (state === "approved" &&
            request.state === ChangeRequestState.APPROVED) ||
          (state === "tested" && request.state === ChangeRequestState.TESTED) ||
          (state === "complete" &&
            request.state === ChangeRequestState.COMPLETED),
      )
      .sort(sortByKey(sortKey, order))
      .map((request) => (
        <TableRow key={request.id}>
          <TableCell>
            <Link component={WLink} to={`/change-requests/${request.id}/`}>
              {request.change.substring(0, 50)}
            </Link>
          </TableCell>
          <TableCell>
            <Link component={WLink} to={`/systems/${request.systemId}/`}>
              {request.systemName}
            </Link>
          </TableCell>
          <TableCell>{formatDateTime(request.timestamp)}</TableCell>
        </TableRow>
      ));
  } else if (requests !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={3}>No open requests found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "change"}
                direction={order}
                onClick={onSortClick("change")}
              >
                Change
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "systemName"}
                direction={order}
                onClick={onSortClick("systemName")}
              >
                System
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "timestamp"}
                direction={order}
                onClick={onSortClick("timestamp")}
              >
                Opened
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestsTable;
