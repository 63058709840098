import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { ChangeRequest } from "src/models";

interface IProps {
  request?: ChangeRequest;
}

const Details = ({ request }: IProps) => (
  <Card>
    <CardContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Change</TableCell>
            <TableCell>
              <Value text={request?.change} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>State</TableCell>
            <TableCell>
              <Value text={request?.state} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Requested by</TableCell>
            <TableCell>
              <Value text={request?.ownerEmail} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default Details;
