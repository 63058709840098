import { ScrollToTop, NotFound } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

import PageWrapper from "src/components/PageWrapper";
import PrivateRoute from "src/components/PrivateRoute";
import { AccessRequestState, ChangeRequestState } from "src/models";
import AccessRequest from "src/pages/AccessRequest";
import AccessRequests from "src/pages/AccessRequests";
import ActionLeaver from "src/pages/ActionLeaver";
import ActionMover from "src/pages/ActionMover";
import Activities from "src/pages/Activities";
import AdjustUserAccessReview from "src/pages/AdjustUserAccessReview";
import ChangeRequest from "src/pages/ChangeRequest";
import ChangeRequests from "src/pages/ChangeRequests";
import CloseUserAccessReview from "src/pages/CloseUserAccessReview";
import CreateAccessRequest from "src/pages/CreateAccessRequest";
import CreateChangeRequest from "src/pages/CreateChangeRequest";
import CreateSystem from "src/pages/CreateSystem";
import CreateUserAccessList from "src/pages/CreateUserAccessList";
import CreateUserAccessReview from "src/pages/CreateUserAccessReview";
import CreateUsernameMapping from "src/pages/CreateUsernameMapping";
import DisableStaff from "src/pages/DisableStaff";
import EditAccessRequest from "src/pages/EditAccessRequest";
import EditChangeRequest from "src/pages/EditChangeRequest";
import EditStaffMember from "src/pages/EditStaffMember";
import EditSystem from "src/pages/EditSystem";
import EditUserAccessListNotes from "src/pages/EditUserAccessListNotes";
import HelpEndpointRoles from "src/pages/HelpEndpointRoles";
import Homepage from "src/pages/Homepage";
import Leaver from "src/pages/Leaver";
import Leavers from "src/pages/Leavers";
import Login from "src/pages/Login";
import Mover from "src/pages/Mover";
import Movers from "src/pages/Movers";
import Staff from "src/pages/Staff";
import StaffMember from "src/pages/StaffMember";
import System from "src/pages/System";
import Systems from "src/pages/Systems";
import TestUserAccessList from "src/pages/TestUserAccessList";
import UploadUserAccessListEvidence from "src/pages/UploadUserAccessListEvidence";
import UserAccessApprovals from "src/pages/UserAccessApprovals";
import UserAccessList from "src/pages/UserAccessList";
import UserAccessReview from "src/pages/UserAccessReview";
import UserAccessReviews from "src/pages/UserAccessReviews";

const ThrowError = () => {
  throw new Error("Test Error");
};

const Router = () => (
  <PageWrapper>
    <ScrollToTop />
    <Switch>
      <PrivateRoute path="/private/">Test</PrivateRoute>
      <PrivateRoute path="/">
        <Homepage />
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/"}>
        <AccessRequests />
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/new/"}>
        <CreateAccessRequest />
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/:id/"}>
        {(params: any) => <AccessRequest requestId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/:id/action/"}>
        {(params: any) => (
          <EditAccessRequest
            requestId={params.id}
            state={AccessRequestState.ACTIONED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/:id/approve/"}>
        {(params: any) => (
          <EditAccessRequest
            requestId={params.id}
            state={AccessRequestState.APPROVED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/:id/deny/"}>
        {(params: any) => (
          <EditAccessRequest
            requestId={params.id}
            state={AccessRequestState.DENIED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/access-requests/:id/withdraw/"}>
        {(params: any) => (
          <EditAccessRequest
            requestId={params.id}
            state={AccessRequestState.WITHDRAWN}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path="/activities/">
        <Activities />
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/"}>
        <ChangeRequests />
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/new/"}>
        <CreateChangeRequest />
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/"}>
        {(params: any) => <ChangeRequest requestId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/approve/"}>
        {(params: any) => (
          <EditChangeRequest
            requestId={params.id}
            state={ChangeRequestState.APPROVED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/complete/"}>
        {(params: any) => (
          <EditChangeRequest
            requestId={params.id}
            state={ChangeRequestState.COMPLETED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/deny/"}>
        {(params: any) => (
          <EditChangeRequest
            requestId={params.id}
            state={ChangeRequestState.DENIED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/test/"}>
        {(params: any) => (
          <EditChangeRequest
            requestId={params.id}
            state={ChangeRequestState.TESTED}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path={"/change-requests/:id/withdraw/"}>
        {(params: any) => (
          <EditChangeRequest
            requestId={params.id}
            state={ChangeRequestState.WITHDRAWN}
          />
        )}
      </PrivateRoute>
      <PrivateRoute path="/leavers/">
        <Leavers />
      </PrivateRoute>
      <PrivateRoute path={"/leavers/:id/"}>
        {(params: any) => <Leaver leaverId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/leavers/:id/action/"}>
        {(params: any) => <ActionLeaver leaverId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/movers/">
        <Movers />
      </PrivateRoute>
      <PrivateRoute path={"/movers/:id/"}>
        {(params: any) => <Mover moverId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/movers/:id/action/"}>
        {(params: any) => <ActionMover moverId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/staff/">
        <Staff />
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/"}>
        {(params: any) => <StaffMember staffId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/staff/:id/edit/roles/">
        {(params: any) => <EditStaffMember staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/disabled/"}>
        {(params: any) => <DisableStaff staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path="/systems/">
        <Systems />
      </PrivateRoute>
      <PrivateRoute path={"/systems/new/"}>
        <CreateSystem />
      </PrivateRoute>
      <PrivateRoute path={"/systems/:id/"}>
        {(params: any) => <System systemId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/systems/:id/edit/"}>
        {(params: any) => <EditSystem systemId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/systems/:id/new-mapping/"}>
        {(params: any) => <CreateUsernameMapping systemId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/systems/:id/new-review/"}>
        {(params: any) => <CreateUserAccessReview systemId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/systems/:id/test-ual/"}>
        {(params: any) => <TestUserAccessList systemId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-lists/:id/"}>
        {(params: any) => <UserAccessList listId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-lists/:id/notes/"}>
        {(params: any) => <EditUserAccessListNotes listId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-lists/:id/upload/"}>
        {(params: any) => <UploadUserAccessListEvidence listId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/"}>
        <UserAccessReviews />
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/:id/"}>
        {(params: any) => <UserAccessReview reviewId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/:id/approvals/"}>
        {(params: any) => <UserAccessApprovals reviewId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/:id/adjust/"}>
        {(params: any) => <AdjustUserAccessReview reviewId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/:id/close/"}>
        {(params: any) => <CloseUserAccessReview reviewId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={"/user-access-reviews/:id/new-ual/"}>
        {(params: any) => <CreateUserAccessList reviewId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/help/endpoint-roles/">
        <HelpEndpointRoles />
      </PrivateRoute>
      <Route path="/login/">
        <Login />
      </Route>
      <Route path="/control/fe-error/">
        <ThrowError />
      </Route>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </PageWrapper>
);

export default Router;
